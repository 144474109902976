import React, { useRef, useEffect, useState, cloneElement, useCallback } from "react";
import styled from "styled-components";

const ObserverDummyBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1000;
  transform: ${props => (props.isLastFiveRows ? "scale(1, 4)" : "scale(1, 8)")};
  transform-origin: ${props => props.isLastFiveRows && "center bottom"};
`;

const ObserverDummyBlockWrapper = (ref, isLastFiveRows) => {
  return <ObserverDummyBlock ref={ref} isLastFiveRows={isLastFiveRows} />;
};

const GridRowsInViewPort = ({
  children,
  index,
  fromBottomFifthRowIndex,
  observer,
  focusedIndex,
  isLastSection,
  setFocusedIndex = () => null
}) => {
  const observerRef = useRef();

  const [showRow, setShowRow] = useState(false);

  useEffect(() => {
    if (!observer) {
      return () => null;
    }
    const setItemVisibility = isIntersecting => {
      if (isIntersecting) {
        setShowRow(true);
      } else {
        const currentFocusedIndex = focusedIndex.current;
        setShowRow(
          currentFocusedIndex !== null
            ? [currentFocusedIndex - 1, currentFocusedIndex, currentFocusedIndex + 1].includes(index)
            : false
        );
      }
    };
    const currentElementRef = observerRef.current;
    Object.defineProperty(currentElementRef, "setItemVisibility", {
      value: setItemVisibility,
      writable: false
    });
    observer.observe(currentElementRef);

    return () => {
      observer.unobserve(currentElementRef);
    };
  }, [observer]);

  const handleOnFocus = useCallback(() => {
    setFocusedIndex(index);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocusedIndex(null);
  }, []);

  const isLastFiveRows = isLastSection && index > fromBottomFifthRowIndex;

  return cloneElement(children, {
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    showRow,
    observerDummyBlock: ObserverDummyBlockWrapper(observerRef, isLastFiveRows)
  });
};

export default React.memo(GridRowsInViewPort);
