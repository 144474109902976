import React, { Children, useEffect, useState, useContext, useRef, useCallback } from "react";

import GridRowsInViewPort from "./GridRowsInViewPortArea";
import RecapGridVisibilityContext from "../recap/RecapGridVisibilityContext";

const GridRowsInViewPortWrapper = ({ children, rowHeight = 40 }) => {
  const arrayChildren = Children.toArray(children);

  const rowsVisibleByDefault = useContext(RecapGridVisibilityContext);

  const focusedIndex = useRef(null);

  const [observer, setObserver] = useState();

  const setFocusedIndex = useCallback(index => {
    focusedIndex.current = index;
  }, []);

  useEffect(() => {
    // Intersection observer setup
    const intersectionCallback = entries => {
      entries.forEach(entry => {
        entry.target.setItemVisibility(entry.isIntersecting);
      });
    };

    var observerRef;
    const handleSheetsObserver = () => {
      const observerOptions = {
        rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`
      };
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        observerRef = new IntersectionObserver(intersectionCallback, observerOptions);
      }
    };

    handleSheetsObserver();
    setObserver(observerRef);

    return () => {
      observerRef.disconnect();
    };
  }, []);

  const fromBottomFifthRowIndex = children.length > 6 ? children.length - 6 : -1;

  return (
    <>
      {Children.map(arrayChildren, (child, index) =>
        child.props.isWithinVisibleArea || rowsVisibleByDefault ? (
          child
        ) : (
          <GridRowsInViewPort
            isLastSection={child.props.isLastSection}
            index={index}
            observer={observer}
            fromBottomFifthRowIndex={fromBottomFifthRowIndex}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
          >
            {child}
          </GridRowsInViewPort>
        )
      )}
    </>
  );
};

export default React.memo(GridRowsInViewPortWrapper);
