import React, { useCallback } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import {
  getMetaKeyName,
  store,
  tickerTypes,
  getTickerDescription,
  siteConfigSelector,
  isAmcUser,
  isAppInWhiteLabelMode,
  tickerSubTypes,
  getAggregatorName,
  getPastDateString
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponentExports";
import Checkbox from "components/inputs/Checkbox";
import UserProfileImage from "components/inputs/UserProfileImage";
import BadgeTip from "components/tooltip/BadgeTip";

export const contextMenuItemType = {
  LINK: {
    id: "link",
    header: "Link",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        position: relative;
        width: 100%;
        padding: 0px 0px 3px 0px;
        align-items: center;
      `;

      const Details = styled.div`
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 1px;
      `;

      const Title = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      `;

      return (
        <Container>
          <Details>
            <Title>{item.label}</Title>
          </Details>
        </Container>
      );
    }
  },
  ACTIVE: {
    id: "active",
    onRender: () => {
      return <strong>ACTIVE</strong>;
    }
  },
  CANCEL: { id: "canceled", label: "CANCEL" },
  LINK_CRYPTO: { id: "link_crypto", label: "Crypto\nExchanges & Wallets" },
  LINK_CARTA: { id: "link_carta", label: "Carta" },
  LINK_DEFI_LOANS: { id: "link_defi_loans", label: "DeFi Loans (Crypto)" },
  CONNECT_STOCK: { id: "link_stock", label: "Stock tickers" },
  CONNECT_CRYPTO: { id: "link_coins", label: "Crypto tickers" },
  CONNECT_METAL: { id: "link_metal", label: "Precious Metals" },
  CONNECT_EXTRAS: { id: "link_extras", label: "Homes, Cars, Domains" },
  CONNECT_HOMES: { id: "link_homes", label: "Homes" },
  CONNECT_CARS: { id: "link_cars", label: "Cars" },
  CONNECT_DOMAINS: { id: "link_domains", label: "Domains" },
  CONNECT_PORTFOLIOS: { id: "link_portfolios", label: "Link a Portfolio" },
  AI_DOCUMENT_IMPORT: { id: "ai_document_import", label: "AI Import" },
  IGNORE: { id: "ignore", label: "Ignore" },
  UNIGNORE: { id: "unignore", label: "Unignore" },
  COPY_LOCATION_BELOW: { id: "copy_location_below", label: "Copy location to rows below" },
  CLEAR_TICKER: { id: "clear_ticker", label: "Clear Ticker / Price" },
  CLEAR_COST: { id: "clear_cost", label: "Clear Cost" },
  RESET_CHANGES: { id: "reset_changes", label: "Reset Changes" },
  ENTER_MANUALLY: { id: "enter_manually", label: "Enter Value manually" },
  ENTER_QTY_PRICE: { id: "enter_qty_price", label: "Enter Quantity & Price" },
  UPDATE_VALUES: {
    id: "update_linked_values",
    label: "Sync Account"
  },
  UPDATE_VALUES_2FAERROR: {
    id: "update_linked_values",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 233px;
        height: ${props => (props.shouldHideDescriptionFooter ? "140px" : "195px")};
        padding: 30px 22px 26px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        box-sizing: border-box;
        margin: -25px -23px -5px;
        user-select: none;
      `;

      const Title = styled.div`
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 3px;
        text-decoration: underline;
        color: #000;
      `;

      const Description = styled.div`
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        margin-bottom: 7px;
        white-space: pre-wrap;
      `;

      const Link = styled.a`
        color: rgba(0, 0, 0, 0.6);
      `;

      const handleOnClick = e => {
        e.stopPropagation();
      };

      const renderDescriptionFooter = () => {
        // if (item.connectivityData && item.connectivityData.disconnect) {
        //   return <Description>{i18n.t("updateValues2FAerror.disconnect.desc")}</Description>;
        // } else

        if (item.connectivityData && !item.connectivityData.reconnectInfo && !item.connectivityData.disconnect) {
          return (
            <Description>
              {i18n.t("updateValues2FAerror.desc2")}
              <br />
              <Link
                href="https://help.kubera.com/article/87-connected-accounts-with-mfa"
                target="_blank"
                onClick={handleOnClick}
              >
                {i18n.t("knowMore")}
              </Link>
            </Description>
          );
        }
      };

      const shouldHideDescriptionFooter = item.connectivityData
        ? item.connectivityData.reconnectInfo || item.connectivityData.disconnect
        : true;
      return (
        <Container shouldHideDescriptionFooter={shouldHideDescriptionFooter}>
          <Title>Sync Account</Title>
          <Description>{item.descriptionText}</Description>
          <Description>{i18n.t("updateValues2FAerror.desc1")}</Description>
          {renderDescriptionFooter()}
        </Container>
      );
    }
  },
  MANAGE_ACCOUNTS: {
    id: "manage_accounts",
    label: "Manage Accounts",
    description: "See all existing accounts and check for new accounts under this connection. "
  },
  REAUTH: { id: "reauth_linked_account", label: "Re-authenticate" },
  UNLINK: { id: "unlink", label: "Disconnect & Archive" },
  UNLINK_NON_ACCOUNTS: { id: "unlink", label: "Disconnect" },
  RECONNECT: {
    id: "reconnect",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 233px;
        padding: 17px 22px 13px;
        margin: -15px -23px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.8);
        user-select: none;
      `;

      const Title = styled.div`
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 7px;
      `;

      const ReconnectBtn = styled.div`
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-decoration: underline;
        cursor: pointer;
        color: #000;
      `;

      const description = i18n.t("contextMenu.reconnect.description");

      const buttonName = i18n
        .t("contextMenu.reconnect.btnName")
        .replace(/%s%/g, getAggregatorName(item?.reconnectInfo?.linkType));

      return (
        <Container>
          <Title>{item.descriptionText}</Title>
          <ReconnectBtn>{buttonName}</ReconnectBtn>
        </Container>
      );
    }
  },
  INSERT_ABOVE: { id: "insert_above", label: "Insert New Row", description: `${getMetaKeyName()}+Shift+Enter` },
  INSERT_BELOW: { id: "insert_below", label: "Insert row below", description: "Shift+Enter" },
  MOVE_ROW: { id: "move_row", label: "Move", description: "To another Sheet / Section" },
  MOVE_SECTION: { id: "move_section", label: "Move" },
  MOVE_SHEET: { id: "move_sheet", label: "Move" },
  MARK_UPDATED: { id: "mark_updated", label: "Mark as Updated" },
  MARK_UNUPDATED: { id: "mark_unupdated", label: "Mark as Stale", description: "To remember to update values" },
  TOGGLE_UPDATED_STATUS: { id: "toggle_updated_status", label: "Mark as Stale", description: "ON / OFF" },
  TOGGLE_STAR_STATUS: { id: "toggle_star_status", label: "Star / Unstar" },
  ARCHIVE: { id: "archive", label: "Archive", description: "If you have sold or exited this asset" },
  TICKER_DESCRIPTION: {
    id: "ticker_description",
    disabled: true,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        color: rgba(0, 0, 0, 0.6);
        text-align: left;
        font-style: normal;
        font-weight: normal;
        margin: -15px -23px -25px -23px;
        padding: 10px 23px 10px 23px;
        background: rgba(196, 196, 196, 0.5);
        font-size: 10px;
        line-height: 140%;
      `;

      const TickerShortName = styled.div`
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 130%;
        padding: 0;
        margin: 0;
      `;

      const Description = styled.div`
        font-size: 10px;
        line-height: 130%;
        padding: 0;
        margin: 0;
      `;

      const ticker = item.ticker;

      const description = `${getTickerDescription(ticker)}`;

      return (
        <Container>
          <TickerShortName>{ticker.shortName}</TickerShortName>
          <Description>{description}</Description>
        </Container>
      );
    }
  },
  LINKED_ACCOUNT_DESCRIPTION: {
    id: "linked_account_description",
    disabled: true,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 100%;
        margin: 0px -23px -25px -23px;
        padding: 10px 23px 10px 23px;
        color: rgba(0, 0, 0, 0.6);
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        background: rgba(196, 196, 196, 0.5);
        line-height: 140%;
      `;

      return (
        <Container
          dangerouslySetInnerHTML={{
            __html: `${item.descriptionText}`
          }}
        />
      );
    }
  },
  INSURANCE_DESCRIPTION: { description: "Click on value to update.\nDrag and drop to move rows", disabled: true },
  UNARCHIVE: { id: "unarchive", label: "Restore" },
  DELETE_PERMANENTLY: { id: "delete_permanently", label: "Delete Permanently" },
  RENAME: { label: "Rename", id: "rename" },
  RENAME_PORTFOLIO: { label: "Rename Portfolio", id: "rename_portfolio" },
  REMOVE_SECTION: { label: "Remove Section", id: "remove_section" },
  SECTION_ADD_ROW: { label: "Insert new row", description: "To enter manually", id: "new_row" },
  SECTION_ADD_SECTION: { label: "Add new section", id: "new_section" },
  SHEET_SELECT: { category: "sheet_select" },
  SHEET_ADD: { label: "New Sheet", id: "sheet_add" },
  ARCHIVED: { label: "See Archived Items", id: "archived" },
  AUTO_UNUPDATE: { label: "Automatically\nMark as Stale", id: "auto_unupdate" },
  DOWNLOAD: { label: "Download", id: "download" },
  DOWNLOADEXCEL: { label: "Download as Excel", id: "downloadexcel" },
  DOWNLOAD_PORTFOLIO_DATA: {
    label: "Download Data",
    id: "download_portfolio_data",
    description: "Excel, CSV, JSON"
  },
  DOWNLOAD_PORTFOLIO_DATA_DOCUMENT: { label: "Download Documents", id: "download_portfolio_data_document" },
  NETWORTH_STATEMENT: { label: "Print Net Worth Statement", id: "networth_statement" },
  DOWNLOAD_DATA_CHATBOTS: {
    label: "Data for AI Chatbots",
    id: "download_data_chatbots",
    description: "Upload your portfolio to ChatGPT, Claude etc. and ask anything"
  },
  SHARE_PORTFOLIO_RO_LINK: {
    label: "Share a Read-Only Link",
    id: "share_portfolio_ro_link",
    description: "For others to view this portfolio.\nNo login required."
  },
  COLLABORATE_PORTFOLIO: {
    label: "Collaborate",
    id: "collaborate",
    description: "Grant access to collaborate on this specific portfolio"
  },
  REMOVE_SHEET: { label: "Remove Sheet", id: "remove_sheet" },
  SIGN_OUT: { label: "Sign Out", id: "sign_out" },
  EDIT: { label: "Edit", id: "edit" },
  DELETE: { label: "Delete", id: "delete" },
  DOCUMENT_SORT: { id: "sort", label: "", description: "SORT", disabled: true },
  DOCUMENT_SORT_LAST_UPLOADED: { id: "sort_last_uploaded", label: "Last Updated" },
  DOCUMENT_SORT_ALPHA: { id: "sort_alpha", label: "Name A-Z" },
  DOCUMENT_SORT_REVERSE_ALPHA: { id: "reverse_alpha", label: "Name Z-A" },
  REMOVE: { id: "remove", label: "Remove" },
  REARRANGE: { id: "rearrange", label: "Rearrange" },
  SUGGESTED_CHART_SETTINGS: { id: "suggested_chart_settings", label: "Settings" },
  ACCOUNT_SETTINGS: { id: "account_settings", label: "Settings" },
  MASK_VALUES: {
    id: "mask_values"
  },
  USER_NAME_EMAIL: {
    id: "user_name_email",
    disableMargin: true,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 10px;
        color: black;
        font-weight: 400;
      `;

      const ProfileImage = styled(UserProfileImage)`
        margin-bottom: 10px;
      `;
      const Name = styled.div`
        font-size: 18px;
        line-height: 120%;
        text-align: center;
      `;
      const Email = styled.div`
        font-size: 10px;
        opacity: 0.6;
        text-align: center;
      `;

      return (
        <Container>
          <ProfileImage imageSize={44} userName={item.label} imageUrl={item.profileImageUrl} />
          <Name>{item.label}</Name>
          <Email>{item.email}</Email>
        </Container>
      );
    }
  },
  USER_PORTFOLIOS: {
    id: "user_portfolios",
    disableMargin: true,
    disablePointer: true,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 100%;
        display: flex;
        flex-direction: ${props => (props.isSelected ? "column" : "row")};
        align-items: center;
        color: black;
        font-weight: 400;
        margin-top: ${props => (props.isSelected ? "25px" : "11px")};
        margin-bottom: 11px;
        line-height: 110%;
        text-align: left;
        cursor: ${props => (props.isSelected ? "default" : "pointer")};
      `;
      const ProfileImage = styled(UserProfileImage)`
        margin-right: ${props => (props.isSelected ? "0px" : "5px")};
        margin-bottom: ${props => (props.isSelected ? "14px" : "0px")};
      `;
      const Details = styled.div`
        display: flex;
        flex-direction: column;
        align-items: ${props => (props.isSelected ? "center" : "flex-start")};
        margin-left: ${props => (props.isSelected ? "0px" : "10px")};
      `;
      const Title = styled.div`
        font-size: ${props => (props.isSelected ? "18px" : "15px")};
        line-height: normal;
        letter-spacing: ${props => (props.isSelected ? "-0.9px" : "-0.7px")};
      `;
      const Description = styled.div`
        margin-top: 2px;
        text-align: ${props => (props.isSelected ? "center" : "left")};
        font-size: 12px;
        line-height: 150%;
        color: #666666;
      `;

      return (
        <Container isSelected={item.isSelected}>
          <ProfileImage
            isSelected={item.isSelected}
            imageSize={item.isSelected ? 44 : 28}
            borderSize={item.isSelected ? undefined : 2}
            userName={item.label}
            imageUrl={item.profileImageUrl}
            secondaryUserName={item.isSelf ? null : item.user.wl?.clientName || item.user.name}
            secondaryImageUrl={item.isSelf ? null : item.secondaryImageUrl}
          />
          <Details isSelected={item.isSelected}>
            <Title isSelected={item.isSelected}>{item.label}</Title>
            <Description isSelected={item.isSelected}>{item.subtext}</Description>
          </Details>
        </Container>
      );
    }
  },
  INACTIVE_CONNECTION: { id: "inactive_connection", label: "Inactive Connection", selected: true, disabled: true },
  LINK_AUTH_ERROR: { id: "link_auth_error", label: "Reactivate" },
  HELP_MENU_WL_CHAT: { id: "chat_support", label: "Report Bugs &\nConnection Issues" },
  HELP_MENU_WL_SUPPORT_EMAIL: { id: "help_menu_wl_support_email" },
  HELP_MENU_REFER_FRIEND: { id: "refer_friend", label: "Earn $100 Credits" },
  HELP_MENU_HELP_CENTER: { id: "help_cnter", label: "Help Center", description: "Learn how to use Kubera" },
  HELP_MENU_CHAT: {
    id: "chat_wl_support",
    label: "Contact Us",
    description: "Report Bugs, Connection issues, Feedback"
  },
  HELP_MENU_TWITTER: { id: "twitter_handle", label: "@KuberaApp", description: "Follow Kubera on Twitter for updates" },
  HELP_MENU_BLOG: { id: "blog_link", label: "Blog" },
  HELP_MENU_HOME: { id: "help_menu_home", label: "Kubera Home" },
  HELP_MENU_FOOTER: { id: "help_menu_footer", disabled: true },
  CHANGE_PHONE_NUMBER: { id: "change_phone_number", label: "Change Number" },
  CHANGE_EMAIL: { id: "change_email", label: "Change Email" },
  LINK_ACCOUNT_PICK_COUNTRY: { id: "link_account_pick_country" },
  INVALID_TICKER: {
    id: "invalid_ticker",
    label: "Sorry, this doesnt look like a valid Currency, Crypto or Stock ticker.",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
      `;
      return <Message>{item.label}</Message>;
    }
  },
  INVALID_CASHFLOW_TICKER: {
    id: "invalid_ticker",
    label: "Sorry, this doesn’t look like a valid Currency or Crypto ticker.",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
      `;
      return <Message>{item.label}</Message>;
    }
  },
  PICK_TICKER: {
    id: "pick_ticker",
    label: "PICK THE RIGHT TICKER",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 10px;
        font-weight: bold;
      `;
      return <Message>{item.label}</Message>;
    }
  },
  WLK_ADMIN_BILLING: { id: "billing_payments", label: "Billing & Payments" },
  MANAGE_CLIENT_USERS: { id: "manage_client_users", label: "Manage Client & Users" },
  REMOVE_ACCESS: { id: "remove_access", label: "Remove access and Archive" },
  ARCHIVE_CLIENT: { id: "archive_client", label: "Archive" },
  INVITE_AGAIN: { id: "invite_again", label: "Invite Again" },
  RE_INVITE_CLIENT: { id: "re_invite_client", label: "Re-Invite Client" },
  CLIENT_CHANGE_EMAIL: { id: "client_change_email", label: "Change Email & Reset Password" },
  INVITE: { id: "invite", label: "Invite" },
  COPY_VIEW_LINK: { id: "copy_view_link", label: "Copy Portfolio Link" },
  RESET_VIEW_LINK: { id: "reset_view_link", label: "Reset Portfolio Link" },
  RO_CLIENT_CHANGE_EMAIL: { id: "ro_client_change_email", label: "Change Email & Reset Passcode" },
  CONTACT_KUBERA: { id: "contact_kubera", label: "Contact Kubera", description: "hello@kubera.com" },
  WLK_CLIENT_DOWNLOAD_DATA: {
    id: "wlk_client_download_data",
    label: "Download Client Data",
    description: "Excel, CSV, JSON"
  },
  MANAGED_ASSET: {
    id: "managed_custodian",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        padding: 0px 0px 3px 0px;
      `;

      const Title = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      `;

      const Description = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 130%;
        color: ${props => props.theme.contextMenuItemDescriptionColor};
      `;

      const ManagedCheckbox = styled(Checkbox)`
        position: absolute;
        top: 3px;
        right: -15px;
      `;

      const isManaged = item.isManaged;
      const isDisabled = item.disabled;

      item.onClick = (e, invokeClick) => {
        if (invokeClick) {
          itemClick(e, item);
        } else {
          item.isManaged = !item.isManaged;
        }
      };

      return (
        <Container>
          <Title>{"Managed"}</Title>
          <Description>{item.managedBy}</Description>
          <ManagedCheckbox
            checked={isManaged}
            disabled={isDisabled}
            isSelfContained={true}
            onChange={(checked, e) => {
              item.onClick(e, true);
            }}
          />
        </Container>
      );
    }
  },
  CLEAR_CASHFLOW: { id: "clear_cashflow", label: "Clear Cash Flow & Start Over" },
  DOWNLOAD_CASHFLOW: { id: "download_cashflow", label: "Download Cash Flow (CSV)" },
  WLK_ASSIGN_CLIENT: {
    id: "wlk_assign_client",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        flex-direction: column;
      `;

      const Title = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 130%;
      `;

      const Description = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
      `;
      return (
        <Container>
          <Title>{"Client assigned to"}</Title>
          <Description>{item.managedBy}</Description>
        </Container>
      );
    }
  },
  TOGGLE_PERCENTAGE_CHANGE_FOR_REPORTS: { id: "toggle_percentage_change_for_reports" },
  REPORT_FILTERS: { id: "report_filters" },
  BAD_CONNECTION: {
    id: "bad_connection",
    disabled: true,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        display: flex;
        flex-direction: column;
        flex: 1
        width: 233px;
        padding: 30px 22px 13px;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        background: #ffc657;
        box-sizing: border-box;
        margin: -25px -23px -16px;
        user-select: none;
      `;

      const Title = styled.div`
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 1px;
      `;

      const Description = styled.div`
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        margin-bottom: 8px;
      `;

      const ReconnectBtn = styled.div`
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        text-decoration: underline;
        cursor: pointer;
        color: #000;
        margin-top: 20px;
      `;

      const LastUpdatedDateString = styled.div`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.6);
      `;

      return (
        <Container
          onClick={e => {
            itemClick(e, { ...item, disabled: false });
          }}
        >
          <Title>{"Out of Sync!"}</Title>
          <Description>{item.descriptionText}</Description>
          <ReconnectBtn>Sync Account</ReconnectBtn>
          {item.lastUpdatedDetails && (
            <LastUpdatedDateString>
              {item.lastUpdatedDetails.dateString.replace("Last update", "Last Sync")}
            </LastUpdatedDateString>
          )}
        </Container>
      );
    }
  },
  START_OVER: {
    id: "start_over",
    disabled: false,
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 233px;
        //height: ${props => (props.isDisconnectActionShown ? "125px" : "105px")};
        padding: 17px 22px 13px;
        margin: -15px -23px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.8);
        user-select: none;
        background: ${props => (props.is2FAError ? "none" : "#ffc657")};
        border-top: ${props => (props.is2FAError ? "none" : "1px solid rgba(0, 0, 0, 0.2)")};
      `;

      const Title = styled.div`
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 7px;
      `;

      const ReconnectBtn = styled.div`
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-decoration: underline;
        cursor: pointer;
        color: #000;
      `;

      const description = item.reconnectInfo
        ? i18n.t("contextMenu.reconnect.description")
        : i18n.t("contextMenu.disconnect.2fa.description");

      const buttonName = item.reconnectInfo
        ? i18n.t("contextMenu.reconnect.btnName").replace(/%s%/g, getAggregatorName(item.reconnectInfo.linkType))
        : i18n.t("contextMenu.disconnect.btnName");
      return (
        <Container is2FAError={item.is2FAError} isDisconnectActionShown={item.shouldDisconnect}>
          <Title>{description}</Title>
          <ReconnectBtn>{buttonName}</ReconnectBtn>
        </Container>
      );
    }
  },
  NEW_ACTION_SECTION: { id: "new_action_section", label: "Create New Section", description: "To group similar assets" },
  NEW_ACTION_SECTION_ROW: {
    id: "new_action_section_row",
    label: "Add New %s%",
    description: "Will be added to a new section"
  },
  RESTORE: { id: "restore", label: "Restore" },
  DELETE_SCENARIO: { id: "delete_scenario", label: "Delete Scenario" },
  DUPLICATE_RULE: { id: "duplicate_rule", label: "Duplicate" },
  MOVE_RULE_TITLE: {
    id: "move_rule_title",
    label: "Move to...",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 14px;
        font-weight: 700;
      `;
      return <Message>{item.label}</Message>;
    }
  },
  MOVE_RULE: {
    id: "move_rule"
  },
  DUPLICATE: { id: "duplicate", label: "Duplicate" },
  CONFLICTING_RULE_TITLE: {
    id: "conflicting_rule_title",
    label: "This rule is in conflict with another rule and has been disabled.",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        margin-bottom: 10px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 12px;
        line-height: 150%;
        font-weight: 400;
      `;
      return <Message>{item.label}</Message>;
    }
  },
  SET_GROWTH: {
    id: "set_growth",
    label:
      "This rule has no effect on your future net worth. Pair it with a growth rule for the asset.<br><a href='https://help.kubera.com/article/136-contribution-rule-is-not-working-in-fast-forward' target='_blank'>More details</a>",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        margin-bottom: 10px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        a {
          color: ${props => props.theme.linkColor};
        }
      `;
      return <Message dangerouslySetInnerHTML={{ __html: item.label }} />;
    }
  },
  DIFF_GROWTH_PCT: {
    id: "diff_growth_pct",
    label:
      "This rule has no effect on your future net worth. Set different growth rates for Cash and the asset.<br><a href='https://help.kubera.com/article/136-contribution-rule-is-not-working-in-fast-forward' target='_blank'>More details</a>",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        margin-bottom: 10px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        a {
          color: ${props => props.theme.linkColor};
        }
      `;
      return <Message dangerouslySetInnerHTML={{ __html: item.label }} />;
    }
  },
  OLD_DATE: {
    id: "old_date",
    label: "This rule doesn't affect your future net worth. Please specify a date starting next month.",
    disabled: true,
    onRender: (itemClick, item) => {
      const Message = styled.div`
        min-height: 8px;
        margin-bottom: 10px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        text-align: left;
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        a {
          color: ${props => props.theme.linkColor};
        }
      `;
      return <Message dangerouslySetInnerHTML={{ __html: item.label }} />;
    }
  },
  DARK_MODE: {
    id: "dark_mode",
    label: "Dark Mode",
    disableDismissOnClick: true
  },
  COPY_VALUE_ABOVE: { id: "copy_value_above", label: "Copy Value to Rows Above" },
  WLK_IMPORT_CASH_FLOW: { id: "wlk_import_cash_flow", label: "Cash Flow" },
  WLK_IMPORT_HISTORY: { id: "wlk_import_historical_value", label: "Historical Value" },
  WLK_IMPORT_ASSETS_DEBTS: { id: "wlk_import_assets_debts", label: "New Assets & Debts" },
  WLK_IMPORT_CURRENT_VALUE: { id: "wlk_import_current_value", label: "Current Value" },
  TRACK_MANUALLY: {
    id: "track_manually",
    label: "Track Manually",
    onRender: (itemClick, item) => {
      const Container = styled.div`
        width: 233px;
        padding: 17px 22px 13px;
        margin: -15px -23px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.8);
        user-select: none;
      `;

      const Title = styled.div`
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 7px;
      `;

      const TrackManuallyBtn = styled.div`
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        // text-decoration: underline;
        cursor: pointer;
        color: #000;
      `;

      // const description = i18n.t("contextMenu.reconnect.description");

      const buttonName = i18n.t("contextMenu.disconnect.btnName");
      return (
        <Container>
          {item.descriptionText && <Title>{item.descriptionText}</Title>}
          <TrackManuallyBtn>{buttonName}</TrackManuallyBtn>
        </Container>
      );
    }
  },
  ACCOUNT_LIST: {
    id: "account_list",
    label: "Account List",
    description: i18n.t("contextMenu.accountList.description")
  }
};

export const getLinkContextMenuItem = forCategory => {
  const linkItem = contextMenuItemType.LINK;
  linkItem.header = "";

  if (forCategory === category.ASSET) {
    linkItem.label = "Connect\nBanks & Brokerages";
  } else if (forCategory === category.DEBT) {
    linkItem.label = "Connect\nLoans & Credit Cards";
  }
  return linkItem;
};

export const getArchiveContextMenuItem = forCategory => {
  const archiveItem = contextMenuItemType.ARCHIVE;

  if (forCategory === category.ASSET) {
    archiveItem.description = "If you have sold or exited this asset";
  } else if (forCategory === category.DEBT) {
    archiveItem.description = "If you have closed this debt";
  } else {
    archiveItem.description = "";
  }
  return archiveItem;
};

export const getUpdateValuesContextMenuItem = (
  withLastUpdateDetails,
  errorType = { is2FAError: false },
  connectivityData
) => {
  const { is2FAError } = errorType;
  const item = is2FAError ? contextMenuItemType.UPDATE_VALUES_2FAERROR : contextMenuItemType.UPDATE_VALUES;
  withLastUpdateDetails.dateString = withLastUpdateDetails.dateString.replace("Last update", "Last Sync");
  if (is2FAError) {
    item.descriptionText = withLastUpdateDetails.dateString;
  } else if (withLastUpdateDetails.isRefreshing === true) {
    item.description = i18n.t("syncing");
  } else if (withLastUpdateDetails.isStale === true) {
    item.description = `<span style='color: rgb(255,0,0)'>${withLastUpdateDetails.dateString}</span>`;
  } else {
    item.description = withLastUpdateDetails.dateString;
  }
  item.connectivityData = connectivityData;
  return item;
};

export const getRestoreArchivedItemsContextMenuItem = rowData => {
  const item = contextMenuItemType.UNARCHIVE;
  item.description = `${i18n.t("archived")}: ${getPastDateString(new Date(rowData.tsArchive * 1000))}`;
  return item;
};

export const getTickerDescriptionContextMenuItem = ticker => {
  if (!ticker || (ticker.type === tickerTypes.FIAT && ticker.subType !== tickerSubTypes.PRECIOUS_METALS)) {
    return null;
  }
  const item = contextMenuItemType.TICKER_DESCRIPTION;
  item.ticker = ticker;
  return item;
};

export const getManagedAssetContextMenuItem = (isManaged, managedBy, disabled = false) => {
  const item = contextMenuItemType.MANAGED_ASSET;
  item.isManaged = isManaged;
  item.disabled = isAppInWhiteLabelMode() === true && isAmcUser(store.getState()) === false;
  item.managedBy = `By ${siteConfigSelector(store.getState()).name}`;
  return item;
};

export const getAssignWlkClient = currentManager => {
  const item = contextMenuItemType.WLK_ASSIGN_CLIENT;
  item.managedBy = currentManager.name;
  return item;
};

export const getLinkedAccountDescriptionMenuItem = linkType => {
  const item = contextMenuItemType.LINKED_ACCOUNT_DESCRIPTION;
  item.descriptionText = i18n.t("contextMenu.bankAndBrokerages.rowDescription");
  return item;
};

export const getStartOverContextMenuItem = (providerAccountId, connectivityData, is2FAError) => {
  const item = contextMenuItemType.START_OVER;
  item.shouldDisconnect = connectivityData.disconnect;
  item.reconnectInfo = connectivityData.reconnectInfo;
  item.is2FAError = is2FAError;
  return item;
};

const MenuWidth = 205;
const MenuItemMinHeight = 29;

const FixWrapper = styled.div`
  position: fixed;
  top: ${props => (props.y > 0 ? props.y : 0) + "px"};
  left: ${props => props.x + "px"};
  z-index: 1010;

  &::before {
    content: " ";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 1px);
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: -1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  position: relative;
  width: ${props => props.width + "px"};
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.contextMenuBackgroundColor};
  box-sizing: border-box;
  max-height: 100vh;
  overflow: auto;
  color: ${props => props.theme.contextMenuContainer};
`;

const Section = styled.div`
  border-bottom: ${props => (props.isLast === true ? "" : "1px solid " + props.theme.contextMenuSectionSeparatorColor)};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props =>
    props.isFirst === true && !props.disablePadding === true
      ? props.isFirstSection === true
        ? "20px"
        : "10px"
      : "0px"};
  margin-bottom: ${props =>
    props.isLast === true && !props.disablePadding === true ? (props.isLastSection === true ? "20px" : "10px") : "0px"};
`;

const ItemHeader = styled.div`
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 130%;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 23px;
  padding-right: 23px;
  min-height: ${MenuItemMinHeight + "px"};
  padding-top: ${props => (props.disablePadding === true ? "0px" : "5px")};
  padding-bottom: ${props => (props.disablePadding === true ? "0px" : "5px")};
  cursor: ${props => (props.disablePointer === true ? "default" : "pointer")};
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  background-color: ${props =>
    props.isFocused === true
      ? props.theme.contextMenuItemSelectedBackgroundColor
      : props.background || props.theme.contextMenuItemBackgroundColor};
  outline: 0;
  border: 0;
  white-space: pre-wrap;

  &:focus {
    background-color: ${props =>
      props.isDisabled === true
        ? props.theme.contextMenuItemBackgroundColor
        : props.theme.contextMenuItemBackgroundColor};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Badge = styled(BadgeTip)`
  margin-bottom: 5px;
  margin-left: 3.5px;
  border: none;
`;

const Label = styled.div`
  min-height: 8px;
  color: ${props => props.theme.contextMenuItemLabelColor};
  color: ${props =>
    props.isDisabled !== true ? props.theme.contextMenuItemLabelColor : props.theme.contextMenuItemDisabledLabelColor};
  text-align: left;
  font-style: normal;
  font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
`;

const Description = styled.div`
  color: ${props => props.theme.contextMenuItemDescriptionColor};
  text-align: left;
  line-height: 130%;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  word-wrap: break-word;
  width: 100%;
`;

export let currentVisibleRef = null;

const ContextMenu = React.forwardRef((props, ref) => {
  const containerRef = React.useRef();
  const { onDismiss = () => null, onSelection = () => null } = props;

  const [visible, setIsVisible] = React.useState(false);
  const [x, setX] = React.useState(-1);
  const [y, setY] = React.useState(-1);
  const [triggeringElement, setTriggeringElement] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [currentlyFocusedItemIndex, setCurrentlyFocusedItemIndex] = React.useState(-1);

  const dismiss = useCallback(() => {
    if (visible === false) {
      return;
    }
    setIsVisible(false);
    setX(-1);
    setY(-1);
    setTriggeringElement(null);
    setItems([]);
    setCurrentlyFocusedItemIndex(-1);
    onDismiss();
  }, [visible, onDismiss]);

  const show = (items, x, y, offsetWidth, triggeringElement) => {
    if (!items) {
      return;
    }

    setIsVisible(true);
    setX(offsetWidth === true ? x + getWidth() : x);
    setY(getYPosition(y));
    setTriggeringElement(triggeringElement);
    setItems(items);
  };

  const selectPreviousItem = useCallback(() => {
    const itemsFlat = items.flat();
    var previousItemIndex = currentlyFocusedItemIndex - 1;

    while (previousItemIndex >= 0 && itemsFlat[previousItemIndex].disabled === true) {
      previousItemIndex -= 1;
    }
    if (previousItemIndex < 0) {
      return;
    }
    setCurrentlyFocusedItemIndex(previousItemIndex);
  }, [currentlyFocusedItemIndex, items]);

  const selectNextItem = useCallback(() => {
    const itemsFlat = items.flat();
    var nextItemIndex = currentlyFocusedItemIndex + 1;
    while (nextItemIndex < itemsFlat.length && itemsFlat[nextItemIndex].disabled === true) {
      nextItemIndex += 1;
    }
    if (nextItemIndex >= itemsFlat.length) {
      return;
    }
    setCurrentlyFocusedItemIndex(nextItemIndex);
  }, [currentlyFocusedItemIndex, items]);

  const handleScroll = () => {
    if (props.hideOnScroll === false) {
      return;
    }
    dismiss();
  };

  const handleClick = useCallback(
    (e, item) => {
      if (item.disabled === true) {
        return;
      }
      if (!item.onClick === false) {
        item.onClick(e, false);
      } else if (!item.disableDismissOnClick === true) {
        dismiss();
      }
      onSelection(item, e);
      e.stopPropagation();
    },
    [onSelection, dismiss]
  );

  const getWidth = () => {
    return props.width === undefined ? MenuWidth : props.width;
  };

  React.useImperativeHandle(ref, () => ({
    show,
    dismiss,
    isVisible: () => visible,
    getWidth
  }));

  React.useEffect(() => {
    if (props.hideOnScroll === true) {
      window.addEventListener("scroll", handleScroll, props.preventGlobalScroll ? false : true);
    }

    return () => {
      if (props.hideOnScroll === true) {
        window.removeEventListener("scroll", handleScroll);
      }
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const handleOutsideClick = e => {
      e.preventDefault();
      if (e.ctrlKey === true) {
        return;
      }
      if (!containerRef || !containerRef.current) {
        return;
      }
      if (e.target === triggeringElement) {
        return;
      }
      if (containerRef.current.contains(e.target)) {
        return;
      }
      const containerPositionInWindow = containerRef.current.getBoundingClientRect();
      const eventX = e.pageX;
      const eventY = e.pageY;
      if (
        eventX >= containerPositionInWindow.x &&
        eventX <= containerPositionInWindow.x + containerPositionInWindow.width &&
        eventY >= containerPositionInWindow.y &&
        eventY <= containerPositionInWindow.y + containerPositionInWindow.height
      ) {
        return;
      }
      dismiss();
    };

    const handleKeyDown = e => {
      if (e.key === "Escape") {
        dismiss();
      } else if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey === true)) {
        selectPreviousItem();
        e.preventDefault();
      } else if (e.key === "ArrowDown" || (e.key === "Tab" && e.shiftKey === false)) {
        selectNextItem();
        e.preventDefault();
      } else if (e.key === "Enter") {
        if (currentlyFocusedItemIndex !== -1) {
          const item = items.flat()[currentlyFocusedItemIndex];
          handleClick(e, item);
        }
        e.preventDefault();
      }
      return true;
    };

    if (visible) {
      document.addEventListener("mousedown", handleOutsideClick, false);
      addKeyboardEventListener(handleKeyDown);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick, false);
      removeKeyboardEventListener(handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick, false);
      removeKeyboardEventListener(handleKeyDown);
    };
  }, [
    visible,
    currentlyFocusedItemIndex,
    dismiss,
    handleClick,
    items,
    selectNextItem,
    selectPreviousItem,
    triggeringElement
  ]);

  const getYPosition = useCallback(
    yPos => {
      // 20px is assumed to be the padding is added to take care of padding/margin if any
      const itemCount = items.flat().length;
      const preCalcMinHeightForItems = MenuItemMinHeight * itemCount + 20 * itemCount;
      const minHeightForItems = containerRef.current
        ? containerRef.current.getBoundingClientRect().height
        : preCalcMinHeightForItems;
      const triggeringElementHeight = triggeringElement === null ? 0 : triggeringElement.getBoundingClientRect().height;

      // If the menu will go outside the visible window shift it up
      if (yPos + minHeightForItems >= window.innerHeight) {
        return yPos - minHeightForItems - triggeringElementHeight;
      }
      return yPos;
    },
    [items, triggeringElement]
  );

  React.useEffect(() => {
    setY(getYPosition(y));
  }, [visible, getYPosition, y]);

  const handleMouseEnter = index => {
    if (items.flat()[index].disabled === true) {
      return;
    }
    setCurrentlyFocusedItemIndex(index);
  };

  const handleMouseLeave = () => {
    setCurrentlyFocusedItemIndex(-1);
  };

  if (visible === false) {
    currentVisibleRef = null;
    return null;
  }

  currentVisibleRef = ref;

  const width = getWidth();

  var itemIndex = -1;

  return (
    <FixWrapper data-cy="context-menu" x={x - width} y={y} ref={containerRef} style={props.style}>
      <Container
        data-private={props.dataPrivate}
        className={props.className}
        width={width}
        onScroll={ContextMenu.stopPropagation}
      >
        {props.children}

        {items.map((section, sectionIndex) => {
          return (
            <Section key={sectionIndex}>
              {section.map((menuItem, index) => {
                itemIndex++;
                if (!menuItem === true) {
                  return null;
                }

                const menuItemIndex = itemIndex;
                return (
                  <ItemContainer
                    key={"item-container" + menuItem.id}
                    disablePadding={!menuItem.disableMargin === false}
                    isFirst={index === 0}
                    isLast={index === section.length - 1}
                    isFirstSection={sectionIndex === 0}
                    isLastSection={sectionIndex === items.length - 1}
                  >
                    {!menuItem.header === false && <ItemHeader>{menuItem.header}</ItemHeader>}
                    <Item
                      key={"item" + menuItem.id}
                      data-cy={"contextMenu_" + menuItem.id}
                      disablePadding={!menuItem.disablePadding === false}
                      disablePointer={!menuItem.disablePointer === false}
                      isFocused={menuItem.disabled !== true && menuItemIndex === currentlyFocusedItemIndex}
                      isFooter={menuItem.isFooter}
                      isDisabled={menuItem.disabled}
                      background={menuItem.background}
                      onClick={e => handleClick(e, menuItem)}
                      onMouseEnter={() => handleMouseEnter(menuItemIndex)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {!menuItem.onRender === true && (
                        <LabelContainer>
                          <Label
                            key={"label" + menuItem.id}
                            isDisabled={menuItem.disabled}
                            isSelected={menuItem.selected === true}
                          >
                            {menuItem.label}
                          </Label>
                          {menuItem.showBadge && <Badge />}
                        </LabelContainer>
                      )}
                      {!menuItem.onRender === false && menuItem.onRender((e, item) => handleClick(e, item), menuItem)}
                      <Description
                        key={"desc" + menuItem.id}
                        dangerouslySetInnerHTML={{
                          __html: menuItem.description
                        }}
                      />
                    </Item>
                  </ItemContainer>
                );
              })}
            </Section>
          );
        })}
      </Container>
    </FixWrapper>
  );
});

ContextMenu.stopPropagation = e => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

export default ContextMenu;
